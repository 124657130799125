<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">推广员</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="d-flex align-items-center">
				<div class="d-flex align-items-center m-1">
					<el-input style="width: 200px" size="small" v-model="search.keyword" placeholder="请输入搜索内容" clearable
						@clear="toSearch" @keyup.enter.native="toSearch">
					</el-input>
					<el-button class="ml-1" @click="toSearch" type="primary" size="mini" icon="el-icon-search">
					</el-button>
				</div>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%">
				<el-table-column align="center" width="120" prop="user_id" label="用户ID"></el-table-column>
				<el-table-column align="center" label="头像" width="90">
					<template slot-scope="scope">
						<viewer>
							<img style="width: 45px;height: 45px;" :src="scope.row.user.avatarUrl">
						</viewer>
					</template>
				</el-table-column>
				<el-table-column align="center" label="昵称">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.user.nickName}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="单位">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.unit.unit_name}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="推广人数">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.spread_count}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="添加时间">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.create_time}}</span>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
					style="display: inline-block;float: right;" background @current-change="pageChange"
					layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				lists: [],
				search: {
					s: 'store/Statistics/staff',
					keyword: '',
					unit_id: 0,
					page: 1,
				},
			}
		},
		created() {
			this.getList();
		},
		methods: {
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
		},
	}
</script>

<style>

</style>
